import React, {useState, useReducer, useEffect} from 'react'
import clx from 'classnames'
import {isEqual, isEmpty, pullAt} from 'lodash'
import {styled, alpha} from '@mui/material/styles'
import {Box, Button, Divider, Menu} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import {categorySizes} from 'assets/data'
import FieldGroup from 'views_v2/lib/snippets/FieldGroup'
import CopytButton from './CopytButton'
import './scss/country-dropdown.scss'

const countryArr = ['US', 'UK', 'JP', 'KR', 'EU', 'CUSTOM']

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({theme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export default function SizesDropdown(p) {
  const {isBulkEdit = false} = p
  const [anchorEl, setAnchorEl] = useState(null)
  const [country, setCountry] = useState('US')
  const [sizes, setSizes] = useState([])
  const [customSize, setCustomSize] = useState(null)
  const open = Boolean(anchorEl)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const {formFields, editMode, isDuplicate = false, value} = p || {}

  useEffect(() => {
    if (Array.isArray(formFields?.size) && !isEmpty(formFields?.size)) {
      const arr = formFields?.size?.map((s) => s?.size)

      if (!isEqual(arr, sizes)) {
        setSizes(formFields?.size)
      }
    }
  }, [])

  useEffect(() => {
    if (!formFields?.size && sizes?.length > 0) {
      setSizes([])
      setCustomSize(null)
    }
  }, [formFields?.size])

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleSizeChange = (size) => {
    const re = /[^0-9\\./A-Za-z]+/
    if (!re.test(size)) {
      if (editMode || isDuplicate) {
        p?.handleSizeChange(size)
        handleClose()
      } else {
        const arr = sizes
        const index = arr.findIndex((s) => size === s?.size)
        if (index > -1) {
          pullAt(arr, index)
        } else {
          arr.push({
            size: size,
            quantity: 1,
          })
        }
        setSizes(arr)
        forceUpdate()
      }
    }
  }

  const isOtherCategory = formFields?.category === 'Accessories' || formFields?.category === 'Apparel'
  let arr = categorySizes[country?.toLowerCase()]

  if (country === 'US') {
    const category = formFields?.category?.toLowerCase()
    if (category?.includes('kid')) {
      arr = categorySizes.kid
    } else if (category?.includes('toddler')) {
      arr = categorySizes.toddler
    } else if (isOtherCategory) {
      arr = categorySizes.apparel
    } else {
      arr = categorySizes.us
    }
  }

  const sizesValue = formFields?.id ? customSize || (!Array.isArray(formFields.size) && formFields.size) : null
  const isCustom = country === 'CUSTOM'

  return (
    <Box className={clx('customized-country', p?.className)} sx={{...p?._containerProps}}>
      <Button
        id="customized-country-button"
        className={clx('country', p.hasError && 'hasError', isBulkEdit && 'isBulkEdit')}
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          ...p?._button,
        }}
      >
        {isDuplicate ? value?.size || 'Enter Size' : sizesValue || 'Enter Size(s)'}
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-country-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Divider width={0} />
        <Box className="select-size">Select Size</Box>
        <Box className="country">
          {countryArr?.map((s, i) => {
            const isActive = country === s
            const disabled = s !== 'CUSTOM' && s !== 'US' && (categorySizes[s?.toLowerCase()]?.length === 0 || isOtherCategory)
            return (
              <Box
                key={`size-${i}`}
                onClick={() => (!disabled ? setCountry(s) : null)}
                className={clx('country-section', isActive ? 'active' : null, disabled ? 'disabled' : null)}
              >
                {s}
              </Box>
            )
          })}
        </Box>
        {isCustom ? (
          <Box className="custom-size">
            <FieldGroup
              sLabel=""
              type="text"
              className="custom-size-input"
              maxLength={30}
              onChange={(e) => setCustomSize(e.target.value)}
              placeholder="Enter size"
              value={isDuplicate ? customSize || value?.size : customSize || sizesValue || ''}
              tabIndex={editMode ? '3' : '15'}
            />
          </Box>
        ) : (
          <Box className="country-body">
            {arr?.map((s, i) => {
              let sizeArr = []
              let array = sizes
              if (!sizes.hasOwnProperty('size') && formFields?.id && /addItem/.test(window.location.href)) {
                sizeArr.push({
                  size: formFields?.size,
                  quantity: 1,
                })
                array = sizeArr
              }
              let isActive = array.some((sz) => sz?.size === s)

              return (
                <Box
                  data-testid={`shoe-size-${i}`}
                  key={`country-size-${i}`}
                  className={clx('country-sizes', isActive && 'active')}
                  onClick={() => {
                    handleSizeChange(s)
                  }}
                >
                  {s}
                </Box>
              )
            })}
          </Box>
        )}

        <Box display="flex" alignItems="center" justifyContent="space-evenly" m="15px 10px" gap={0.5}>
          <CopytButton
            variant="outline"
            color="primary"
            onClick={() => {
              handleClose()
            }}
            sx={{width: '130px'}}
          >
            Cancel
          </CopytButton>
          {(!editMode || (editMode && isCustom) || (isDuplicate && isCustom)) && (
            <CopytButton
              variant="contained"
              color="primary"
              data-testid={`apply-button-sizes`}
              onClick={() => {
                if (isCustom && !editMode && !isDuplicate) {
                  sizes.push({
                    size: customSize,
                    quantity: 1,
                  })
                }
                p?.handleSizeChange(editMode || (isDuplicate && isCustom) ? customSize : sizes)
                handleClose()
              }}
              sx={{width: '130px'}}
            >
              Apply
            </CopytButton>
          )}
        </Box>
      </StyledMenu>
    </Box>
  )
}
