export const REGEX_SHORT_ISO_DATE = /\d{4}-[01]\d-[0-3]\dT([0-5][0-9]):([0-5][0-9]):([0-5][0-9])/
export const REGEX_JSON_DATE = /^(\/Date)/
export const REGEX_MOBILE_NUMBER = /^[0-9]{10}$/
export const REGEX_US_MOBILE_NUMBER = /^(\+1|1)?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export const REGEX_NUMBERS_ONLY = /^[0-9]*$/
export const REGEX_DECIMAL_NUMBERS = /[^0-9\\.]+/
export const REGEX_EMAIL_FORMAT =
  /^(?!.{64})(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

// For User Password
// Min of 8 characters (upper case, a lower case, a special character and a number)
export const REGEX_CUSTOM_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

export const REGEX_WEBSITE_FORMAT = /^(https?:\/\/)?([\da-zA-Z\.-]+)\.([a-zA-Z\\.]{2,6})([\/\w \.-]*)*\/?$/
export const REGEX_URL = /^(https?:\/\/)[^\s$.?#].[^\s]*$/
